import styled, { css } from 'styled-components';
import { Link, NavLink as RawNavLink } from 'react-router-dom';
import { color, ui } from './colors';
import { capitalize } from 'utils';
import MDSpinner from 'react-md-spinner';
import tinycolor from 'tinycolor2';
import { Stack } from '@tymate/margaret';
import { media, size, spacing } from './utils';
import { keys } from 'lodash';

export { spacing, size, media };

export const MAIN_HEADER_HEIGHT = '170px';
export const MAIN_SIDEBAR_WIDTH = '250px';
export const SECONDARY_SIDEBAR_WIDTH = '420px';
export const MAIN_HEADER_MAINTENANCE_HEIGHT = '60px'

export const Box = styled.div`
   background-color: #fff;
   border-radius: 16px;
   padding: ${props => (props.padded ? spacing(2) : props.bare ? 0 : spacing())};
   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
   color: ${({ theme }) => theme.primary};
`;

export const Container = styled.div`
   width: 100%;
   max-width: 1200px;
   margin-left: auto;
   margin-right: auto;
   padding-left: ${({ theme }) => theme.spacing()};
   padding-right: ${({ theme }) => theme.spacing()};
   padding-top: ${({ theme }) => theme.spacing()};

   ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-top: 0;
  `};

   ${props =>
      props.hasVerticalPadding &&
      css`
         padding-top: ${({ theme }) => theme.spacing()};
         padding-bottom: ${({ theme }) => theme.spacing()};

         ${media.tablet`
        padding-top: ${({ theme }) => theme.spacing(2)};
        padding-bottom: ${({ theme }) => theme.spacing(2)};
      `};
      `};

   ${props =>
      props.medium &&
      css`
         max-width: 1000px;
      `}

   ${props =>
      props.narrow &&
      css`
         max-width: 832px;
      `};

   ${props =>
      props.tight &&
      css`
         max-width: 540px;
      `};

   ${props =>
      props.leftAligned &&
      css`
         margin-left: 0;
         margin-right: 0;
      `};

   ${props =>
      props.isFull &&
      css`
         width: 100%;
      `};

   ${props =>
      props.hasNoPadding &&
      css`
         padding: 0;
      `};
`;

export const List = styled.ul`
   padding-left: 0;
   margin-top: 0;
   margin-bottom: 0;
   list-style-type: none;

   ${({ gutterSize }) =>
      Boolean(gutterSize) &&
      css`
         > li + li {
            margin-top: ${({ theme }) => theme.spacing(gutterSize)};
         }
      `}
`;

export const InlineList = styled(List)`
   display: flex;
   flex-wrap: wrap;
`;

export const NavLink = styled(RawNavLink)`
   text-decoration: none;
`;

export const LoadingButton = ({ isLoading, children, ...props }) => (
   <Button {...props}>
      {isLoading && <MDSpinner style={{ marginRight: 8 }} singleColor='currentColor' size={16} />}
      {children}
   </Button>
);

export const ButtonLink = styled(({ isPrimary, hasNoBorder, isBare, children, ...props }) => (
   <Link {...props}>{children}</Link>
))`
   font-weight: 600;
   border: 1px solid rgba(0, 0, 0, 0.08);
   padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing()};
   border-radius: 4px;
   cursor: pointer;
   display: flex;
   align-items: center;
   outline: none;

   color: ${({ theme }) => theme.primary};
   background-color: #fff;
   text-decoration: none;

   &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
   }

   > svg {
      margin-right: ${({ theme }) => theme.spacing(0.25)};
      font-size: ${size(1)};
   }

   &:disabled,
   &:disabled:hover {
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.lightGrey};
      cursor: not-allowed;
      box-shadow: none;
   }

   &.active {
      background-color: ${({ theme }) => theme.white};
   }

   ${props =>
      props.isPrimary &&
      css`
         color: ${({ theme }) => theme.white};
         background-color: ${({ theme }) => theme.primary};

         &:hover {
            box-shadow: 0 4px 8px 0 ${({ theme }) => theme.darkGrey};
            background-color: ${({ theme }) => theme.primary};
            color: ${({ theme }) => theme.white};
         }

         &:disabled,
         &:disabled:hover {
            background-color: ${({ theme }) => theme.background};
            color: ${({ theme }) => theme.lightGrey};
            cursor: not-allowed;
            box-shadow: none;
         }

         > svg {
            color: ${({ theme }) => theme.primaryButtonIconColor};
         }
      `};

   img {
      width: ${({ theme }) => theme.spacing()};
      margin-right: ${({ theme }) => theme.spacing(0.5)};
   }

   ${props =>
      props.big &&
      css`
         padding: ${({ theme }) => theme.spacing(0.75)} ${({ theme }) => theme.spacing()};
         font-size: ${size(1)};
      `};

   ${props =>
      props.switch &&
      css`
         color: ${({ theme }) => theme.lightGrey};
         background-color: ${({ theme }) => theme.white};
         border: 1px solid ${({ theme }) => theme.background};

         &.active {
            color: ${({ theme }) => theme.primary};
            background-color: ${({ theme }) => theme.background};
            border: 1px solid ${({ theme }) => theme.darkGrey};
         }
      `};
`;

export const Button = ButtonLink.withComponent('button');

export const Buttons = styled.div`
   display: flex;
   justify-content: center;
   margin-left: ${({ theme }) => theme.spacing(-1)};
   flex-wrap: wrap;

   ${props =>
      props.left &&
      css`
         justify-content: flex-start;
      `};

   ${props =>
      props.verticalCenter &&
      css`
         align-items: center;
      `}

   ${props =>
      props.right &&
      css`
         justify-content: flex-end;
      `};

   ${props =>
      props.marged &&
      css`
         padding-top: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      props.big &&
      css`
         font-size: ${size(1)};
      `};

   ${props =>
      props.hasBorderTop &&
      css`
         position: relative;
         padding-top: ${({ theme }) => theme.spacing()};

         &:before {
            position: absolute;
            display: block;
            top: 0;
            left: ${({ theme }) => theme.spacing()};
            right: 0;
            content: '';
            border-top: 1px solid ${({ theme }) => theme.separator};
         }
      `};

   > * {
      margin-left: ${({ theme }) => theme.spacing()};
      margin-bottom: ${({ theme }) => theme.spacing()};
   }
`;

export const LinkButton = styled.button`
   border: 0;
   padding: 0;
   text-decoration: ${props => (props.underlined ? 'underline' : 'none')};
   background-color: transparent;
   outline: none;
   cursor: pointer;

   &:hover {
      opacity: 0.9;
   }
`;

export const Ul = styled(List)`
   margin-left: ${({ theme }) => theme.spacing(-1)};
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
`;

export const Li = styled.li`
   flex: 0 0 100%;
   padding-left: ${({ theme }) => theme.spacing()};
   padding-bottom: ${({ theme }) => theme.spacing()};
   display: flex;
   align-items: stretch;

   ${media.tablet`
    flex: 0 0 50%;
  `};

   ${media.medium`
    flex: 0 0 33.333%;
  `};
`;

export const Title = styled.h2`
   font-weight: 600;
   margin-top: 0;
   margin-bottom: ${({ theme }) => theme.spacing()};
   font-size: ${size(2)};

   ${props =>
      props.isBig &&
      css`
         font-size: ${size(3)};
         margin-top: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      props.isMargedTop &&
      css`
         margin-top: ${({ theme }) => theme.spacing(2)};
      `};

   ${props =>
      props.hasSmallTopMargin &&
      css`
         margin-top: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      props.hasBorderBottom &&
      css`
         padding-bottom: ${({ theme }) => theme.spacing()};
         margin-bottom: ${({ theme }) => theme.spacing()};
         border-bottom: 1px solid ${({ theme }) => theme.separator};
      `};

   ${props =>
      props.size === 'medium' &&
      css`
         font-size: ${size(1)};
         margin-bottom: ${({ theme }) => theme.spacing(0.5)};
      `}
`;

export const Card = styled.div`
   background-color: #fff;
   border-radius: ${({ theme }) => theme.borderRadius};
   border: 1px solid ${({ theme }) => theme.separator};
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   width: 100%;
   margin-bottom: ${({ theme }) => theme.spacing()};
   color: inherit;
   text-decoration: none;
   transition: box-shadow 100ms ease;

   ${props =>
      props.to &&
      css`
         &:hover {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
         }
      `};

   > *:last-child {
      margin-bottom: 0;
   }

   ${props =>
      props.isSemiPadded &&
      css`
         padding: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      props.hasSmallPadding &&
      css`
         padding: ${({ theme }) => theme.spacing(0.5)};
      `};

   ${props =>
      props.isPadded &&
      css`
         padding: ${({ theme }) => theme.spacing(1.5)};
      `};

   ${props =>
      props.hasNoHeader &&
      css`
         padding-top: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      (props.isMargedBottom || props.isMargedVertically) &&
      css`
         margin-bottom: ${({ theme }) => theme.spacing(2)};
      `};

   ${props =>
      (props.isMargedTop || props.isMargedVertically) &&
      css`
         margin-top: ${({ theme }) => theme.spacing(2)};
      `};

   ${props =>
      props.hasNoBottomMargin &&
      css`
         margin-bottom: 0;
      `};
`;

export const Cards = styled.div`
   margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const CardHeaderActions = styled.div`
   flex: 0 0 100%;
   margin-top: ${({ theme }) => theme.spacing()};
   display: flex;

   ${({ variant }) =>
      variant === 'bareTop' &&
      css`
         margin-top: 0;
      `};

   svg {
      display: flex;
   }

   ${media.tabletMini`
    flex: 0;
    margin-top: 0;
  `};
`;

export const CardHeaderMeta = styled.div`
   flex: 1;
`;

export const CardTitle = styled.h2`
   margin-top: 0;
   font-size: ${size(2)};
   display: flex;
   align-items: center;
   font-weight: 700;
   margin-bottom: ${({ theme }) => theme.spacing()};

   > svg {
      margin-right: ${({ theme }) => theme.spacing(0.5)};
      color: ${ui('textLight')};
      width: 1.15em;
      height: 1.15em;
   }
`;

export const CardTitleAlt = styled(CardTitle)`
   text-transform: none;
   font-weight: 600;
   margin-bottom: 0;
`;

export const CardSubtitle = styled.div`
   color: ${({ theme }) => theme.textLight};
   margin-top: ${({ theme }) => theme.spacing(0.25)};
`;

export const CardHeader = styled.header`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   flex: 0 0 100%;
   padding: ${({ theme }) => theme.spacing()};

   ${media.tablet`
    padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(1.5)};
  `};

   ${props =>
      props.hasBorderBottom &&
      css`
         border-bottom: 1px solid ${({ theme }) => theme.separator};
      `};

   ${props =>
      props.isSemiPadded &&
      css`
         padding-left: ${({ theme }) => theme.spacing()};
         padding-right: ${({ theme }) => theme.spacing()};
      `};
`;

export const CardContent = styled.article`
   flex: 0 0 100%;
   padding: 0 ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()};

   ${media.tablet`
    padding: 0 ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing()};
  `};

   ${props =>
      props.hasEqualPadding &&
      css`
         padding: ${({ theme }) => theme.spacing()};

         ${media.tablet`padding: ${({ theme }) => theme.spacing()}`};
      `};

   ${props =>
      props.isBare &&
      css`
         padding: 0;

         ${media.tablet`padding: 0;`};
      `};

   ${props =>
      props.variant === 'bareBottom' &&
      css`
         padding-bottom: 0;

         ${media.tablet`
        padding-bottom: 0;
      `};
      `};

   + article {
      border-top: 1px solid ${({ theme }) => theme.separator};
   }
`;

export const CardActions = styled.div``;

export const CardMeta = styled.div`
   margin: 0;
   color: ${({ theme }) => theme.textLight};
`;

export const CardFooter = styled.div`
   text-align: center;
   flex: 0 0 100%;
   padding: ${({ theme }) => theme.spacing()};

   ${props =>
      props.variant === 'bareBottom' &&
      css`
         padding-bottom: 0;
      `};
`;

export const CardFooterLink = styled(Link)`
   color: ${({ theme }) => theme.primary};
   font-weight: 600;
   text-decoration: none;

   &:hover {
      opacity: 0.8;
   }
`;

export const ButtonReset = styled.button`
   appearance: none;
   outline: none;
   padding: 0;
   background: transparent;
   border: 0;
   text-align: left;
   cursor: pointer;

   svg {
      display: flex;
   }
`;

export const ArchiveButton = styled(ButtonReset)`
   color: ${({ theme }) => theme.success};
   border: 1px solid currentColor;
   background: linear-gradient(to bottom, #fff, #ebf3f5);
   padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing()};
   border-radius: ${({ theme }) => theme.borderRadius};
   font-weight: 600;

   &:hover {
      opacity: 0.8;
   }
`;

export const getAddress = address => (!Boolean(address) ? null : capitalize(address.full));

export const SwitchListItem = styled.li`
   position: relative;
   padding: ${({ theme }) => theme.spacing(0.5)} 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   transition: opacity 150ms ease;
   flex-wrap: wrap;

   + li:before {
      position: absolute;
      content: '';
      display: block;
      left: ${({ theme }) => theme.spacing()};
      top: 0;
      right: 0;
      border-top: 1px solid ${({ theme }) => theme.separator};
   }

   ${props =>
      props.disabled &&
      css`
         opacity: 0.35;
      `};
`;

export { color, ui };

export const AccessibleButton = styled(ButtonReset)`
   display: inline-block;
   align-items: center;
   color: inherit;
   background: linear-gradient(to bottom, #fff, ${({ theme }) => theme.background});
   padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) => theme.spacing(0.5)};
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: ${({ theme }) => theme.borderRadius};
   margin-left: ${({ theme }) => theme.spacing(0.5)};
   font-size: ${size(-1)};
   cursor: pointer;
   text-decoration: none;

   ${props =>
      props.medium &&
      css`
         padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.75)};
         font-size: ${size()};
      `};

   ${props =>
      props.big &&
      css`
         padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.75)};
         font-size: ${size(1)};
      `};
`;

export const AccessibleLinkButton = styled(({ medium, big, children, ...props }) => <Link {...props}>{children}</Link>)`
   appearance: none;
   outline: none;
   padding: 0;
   background: transparent;
   border: 0;
   text-align: left;
   display: inline-block;
   align-items: center;
   color: inherit;
   background: linear-gradient(to bottom, #fff, ${({ theme }) => theme.background});
   padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) => theme.spacing(0.5)};
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: ${({ theme }) => theme.borderRadius};
   margin-left: ${({ theme }) => theme.spacing(0.5)};
   font-size: ${size(-1)};
   cursor: pointer;
   text-decoration: none;

   ${props =>
      props.medium &&
      css`
         padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.75)};
         font-size: ${size()};
      `};

   ${props =>
      props.big &&
      css`
         padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.75)};
         font-size: ${size(0)};
         font-weight: 600;
      `};
`;

export const Description = styled.div`
   max-width: 45em;
   line-height: 1.5;
   white-space: pre-wrap;
   margin-top: ${({ theme }) => theme.spacing()};
   margin-bottom: ${({ theme }) => theme.spacing()};

   ${props =>
      props.variant === 'faded' &&
      css`
         color: ${({ theme }) => theme.textLight};
      `};
`;

export const Section = styled.section`
   margin-top: ${({ theme }) => theme.spacing(2)};
   margin-bottom: ${({ theme }) => theme.spacing(2)};

   ${props =>
      props.padded &&
      css`
         padding: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      props.bareTop &&
      css`
         margin-top: 0;
      `};

   ${props =>
      props.paddedTop &&
      css`
         padding-top: ${({ theme }) => theme.spacing()};
      `};

   ${props =>
      props.bareBottom &&
      css`
         margin-bottom: 0;
      `};
`;

export const Media = styled.div`
   display: flex;
   align-items: flex-start;
   color: inherit;
   text-decoration: none;

   ${props =>
      props.hasTopMargin &&
      css`
         margin-top: ${({ theme }) => theme.spacing(2)};
      `};

   ${props =>
      props.hasBottomMargin &&
      css`
         margin-bottom: ${({ theme }) => theme.spacing(2)};
      `};

   ${props =>
      props.center &&
      css`
         align-items: center;
      `};

   ${props =>
      props.stretch &&
      css`
         align-items: stretch;
      `};
`;

export const MediaImage = styled.div`
   vertical-align: middle;
   white-space: nowrap;
   display: flex;
   align-items: center;

   ${props =>
      props.center &&
      css`
         vertical-align: middle;
      `};

   ${props =>
      props.top &&
      css`
         vertical-align: top;
      `};
`;

export const MediaBody = styled.div`
   flex: 1;
   padding-left: ${({ theme }) => theme.spacing()};

   ${props =>
      props.tight &&
      css`
         padding-left: ${({ theme }) => theme.spacing(0.5)};
      `};

   ${props =>
      props.secondary &&
      css`
         flex: 0;
      `};
`;

export const AttachmentLinkIndicator = styled.span`
   border: 1px solid ${({ theme }) => theme.separator};
   display: block;
   border-radius: ${({ theme }) => theme.borderRadius};
   color: inherit;
   padding: ${({ theme }) => theme.spacing(0.5)};
   text-decoration: none;

   svg {
      color: ${({ theme }) => theme.error};
      font-size: ${size(2)};
   }
`;

export const Subtitle = styled.h3`
   font-size: ${size(1)};
   font-weight: 600;
   margin-top: ${({ theme }) => theme.spacing(2)};
   margin-bottom: ${({ theme }) => theme.spacing(0.5)};

   ${props =>
      props.bareTop &&
      css`
         margin-top: 0;
      `};

   ${({ variant }) =>
      variant === 'bare' &&
      css`
         margin-top: 0;
         margin-bottom: 0;
      `};

   ${props =>
      props.alt &&
      css`
         color: ${({ theme }) => theme.textLight};
         padding-bottom: ${({ theme }) => theme.spacing(0.5)};
         position: relative;
         margin-bottom: ${({ theme }) => theme.spacing()};

         &:before {
            content: '';
            position: absolute;
            left: ${({ theme }) => theme.spacing(-1)};
            right: ${({ theme }) => theme.spacing(-1)};
            border-top: 1px solid ${({ theme }) => theme.separator};
            bottom: 0;
         }
      `};
`;

export const PageHeader = styled.header`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   background-color: ${({ theme }) => theme.primary};
   text-align: center;
   color: #fff;
   z-index: 10;

   ${props =>
      props.hideOnDesktop &&
      css`
         ${media.tablet`
        display: none;
      `};
      `};

   ${media.tablet`
    position: static;
    background-color: transparent;
    color: inherit;
    text-align: left;

    ${({ variant }) =>
       variant === 'standout' &&
       css`
          background-color: #fff;
          border-bottom: 1px solid ${({ theme }) => theme.separator};
          margin-bottom: ${({ theme }) => theme.spacing()};
       `}
  `};
`;

export const PageHeaderMobileAction = styled.div`
   position: absolute;
   top: 50%;
   right: ${({ theme }) => theme.spacing()};
   z-index: 1;
   transform: translateY(-50%);
   font-size: ${size(3)};
   display: flex;
   color: #fff;
   text-decoration: none;
`;

export const PageTitle = styled(Title)`
   padding: ${({ theme }) => theme.spacing()};
   margin-bottom: 0;
   color: #fff;
   font-size: ${size(1)};
   line-height: ${({ theme }) => theme.spacing(1.5)};

   ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()};
    display: flex;
    color: inherit;
  `};
`;

export const MobileNav = styled(List)``;

export const MobileNavItem = styled.li`
   + li {
      margin-top: ${({ theme }) => theme.spacing()};
   }

   ${props =>
      props.spaced &&
      css`
         margin-top: ${({ theme }) => theme.spacing(1.5)};
      `};
`;

export const MobileNavItemLink = styled(Link)`
   display: flex;
   color: inherit;
   text-decoration: none;
   padding: ${({ theme }) => theme.spacing(0.75)} ${({ theme }) => theme.spacing()};
   background-color: #fff;
   border-radius: ${({ theme }) => theme.borderRadius};
   border: 1px solid ${({ theme }) => theme.separator};
   align-items: center;
   transition: box-shadow 100ms ease;
   box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
   width: 100%;
   text-align: left;

   &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
   }

   ${props =>
      props.barePadding &&
      css`
         ${media.tablet`
       padding-top: ${({ theme }) => theme.spacing(0.5)};
       padding-bottom: ${({ theme }) => theme.spacing(0.5)}
      `};
      `};
`;

export const MobileNavItemIcon = styled.span`
   font-size: ${size(2)};
   color: ${({ theme }) => theme.textLighter};
`;

export const MobileNavItemLabel = styled.span`
   display: block;
   padding-right: ${({ theme }) => theme.spacing()};
   flex: 1;
`;

export const Heading = styled.h3`
   font-size: ${size(1)};
   margin-top: 0;
   margin-bottom: ${({ theme }) => theme.spacing()};
   font-weight: 400;

   ${({ emphasized }) =>
      emphasized &&
      css`
         font-weight: 600;
      `}

   ${props =>
      props.isSemiBare &&
      css`
         margin-bottom: ${({ theme }) => theme.spacing(0.5)};
      `};

   > img {
      width: 24px;
      margin-right: ${({ theme }) => theme.spacing(0.5)};
      display: inline-block;
      vertical-align: middle;
   }
`;

export const SubHeading = styled.h4`
   font-size: ${size(0)};
   font-weight: 600;
`;

export const SubHeadingLabel = styled.span`
   color: ${({ theme }) => theme.textLight};
   font-weight: 400;
`;

export const ReportContainer = styled.div`
   margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const DefaultLink = styled(Link)`
   font-weight: 500;
   text-decoration: none;
   color: ${({ theme }) => theme.primary};
   cursor: pointer;
`;

export const DefaultLinkWrapper = styled.div`
   margin-top: ${({ theme }) => theme.spacing()};
   margin-bottom: ${({ theme }) => theme.spacing()};
`;

export const LabelText = styled.span`
   color: ${({ theme }) => theme.textLight};
`;

export const ButtonNew = styled.button`
   font-weight: 600;
   border: 1px solid rgba(0, 0, 0, 0.08);
   padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing()};
   border-radius: 4px;
   cursor: pointer;
   display: flex;
   align-items: center;
   outline: none;
   background-color: #fff;
   color: ${({ theme }) => theme.primary};
   text-decoration: none;
   transition: box-shadow 100ms ease;

   &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
   }

   > svg {
      margin-right: ${({ theme }) => theme.spacing(0.25)};
      font-size: ${size(1)};
   }

   ${props =>
      props.isPrimary &&
      css`
         border: 0;
         box-shadow: ${({ theme }) => theme.primaryButtonBoxShadow};
         background: linear-gradient(
            to bottom,
            ${({ theme }) => theme.primaryButtonGradientOne},
            ${({ theme }) => theme.primaryButtonGradientTwo}
         );
         color: ${({ theme }) => theme.primaryButtonTextColor};

         &:hover {
            box-shadow: ${({ theme }) => theme.primaryButtonBoxShadowHover};
         }

         > svg {
            color: ${({ theme }) => theme.primaryButtonIconColor};
         }
      `};

   ${props =>
      props.big &&
      css`
         padding: ${({ theme }) => theme.spacing(0.75)} ${({ theme }) => theme.spacing()};
         font-size: ${size(1)};
      `};

   &:disabled {
      background: ${({ theme }) => theme.disabledBackground};
      color: ${({ theme }) => theme.disabledColor};
      cursor: not-allowed;

      &:hover {
         box-shadow: none;
      }
   }
`;

export const TitleAndAction = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: ${({ theme }) => theme.spacing()};
   flex-wrap: wrap;
   margin-top: ${({ theme }) => theme.spacing(-0.5)};
   margin-left: ${({ theme }) => theme.spacing(-0.5)};

   > * {
      padding-top: ${({ theme }) => theme.spacing(0.5)};
      padding-left: ${({ theme }) => theme.spacing(0.5)};
   }

   ${props =>
      props.hasNoBottomMargin &&
      css`
         margin-bottom: 0;
      `};

   ${props =>
      props.hasTopSeparation &&
      css`
         margin-top: ${({ theme }) => theme.spacing()};
         margin-bottom: 0;
      `};

   ${props =>
      props.isHiddenOnLargeViewports &&
      css`
         ${media.tablet`
        display: none;
      `};
      `};

   > h3 {
      margin-bottom: 0;
   }
`;

export const ActionButton = styled(ButtonReset)`
   background-color: ${({ theme }) => theme.background};
   border-radius: 100%;
   color: ${({ theme }) => theme.primary};
   font-size: ${size(2)};
   padding: ${({ theme }) => theme.spacing(0.25)};

   ${props =>
      props.isReadOnly &&
      css`
         background-color: #fff;
         cursor: initial;
      `};

   ${props =>
      props.isActive &&
      `
      background-color: #fff;
    `}
`;

export const FullCard = styled.div`
   padding: ${({ theme }) => theme.spacing()};
   margin: ${({ theme }) => theme.spacing(-1)} ${({ theme }) => theme.spacing(-1)} ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(-1)};
   display: flex;
   flex-direction: column;
   background-color: #fff;
   border-bottom: 1px solid ${({ theme }) => theme.separator};
   color: inherit;
   text-decoration: none;

   ${media.tablet`
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.separator};
  `};

   ${props =>
      props.variant === 'dashboard' &&
      css`
         margin-top: 0;
         border-top: 1px solid ${({ theme }) => theme.separator};
         padding: 0;
      `};
`;

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${({ theme }) => theme.spacing(-1)};
    margin-left: ${({ theme }) => theme.spacing(-1)};

    ${props =>
       props.verticalAlign === 'center' &&
       css`
          align-items: center;
       `}

    ${media.tablet`
    width: calc(100% + ${({ theme }) => theme.spacing(2)});
  `}

    ${props =>
       props.verticalAlign === 'stretch' &&
       css`
          align-items: stretch;

          > * {
             height: 100%;
          }
       `}

    ${media.tablet`
    width: calc(100% + ${({ theme }) => theme.spacing()});
  `}

    ${props =>
       props.gutterVariant === 'medium' &&
       css`
          margin-top: ${({ theme }) => theme.spacing(-1.5)};
          margin-left: ${({ theme }) => theme.spacing(-1.5)};
          width: calc(100% + ${({ theme }) => theme.spacing(1.5)});

          > * {
             padding-top: ${({ theme }) => theme.spacing(1.5)} !important;
             padding-left: ${({ theme }) => theme.spacing(1.5)} !important;
          }
       `};

    ${props =>
       props.gutterVariant === 'large' &&
       css`
          margin-top: ${({ theme }) => theme.spacing(-2)};
          margin-left: ${({ theme }) => theme.spacing(-2)};
          width: calc(100% + ${({ theme }) => theme.spacing(2)});

          > * {
             padding-top: ${({ theme }) => theme.spacing(2)} !important;
             padding-left: ${({ theme }) => theme.spacing(2)} !important;
          }
       `};

    ${props =>
       props.variant === 'singleLine' &&
       css`
          margin-left: 0;
          width: 100% !important;

          > * {
             padding-left: 0 !important;

             + * {
                padding-left: ${({ theme }) => theme.spacing()} !important;
             }
          }
       `}
`;

export const Cell = styled.div`
   flex: 1;
   padding-top: ${({ theme }) => theme.spacing()};
   padding-left: ${({ theme }) => theme.spacing()};
   min-width: 0;

   ${props =>
      props.sizes &&
      css`
         flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

         ${keys(props.sizes)
            .filter(key => key !== 'default')
            .map(
               breakpoint => css`
                  ${media[breakpoint]`flex: 0 0 ${Math.floor(props.sizes[breakpoint] * 10000) / 100}%`};
               `
            )};
      `};

   ${props =>
      props.min &&
      css`
         flex: 0;
         white-space: nowrap;
      `};

   ${props =>
      props.hiddenUntilTablet &&
      css`
         display: none;

         ${media.tablet`
        display: block;
      `}
      `}
`;

export const Legend = styled.p`
    color: ${({ theme }) => theme.textLight};
    display: block;
    font-weight: 400;
    font-size: ${size(-1)};

    ${props =>
       props.size === 'normal' &&
       css`
          font-size: ${size(0)};
          line-height: 1.2;
       `}

    ${({ variant }) =>
       variant === 'default' &&
       css`
          color: ${({ theme }) => theme.text};
       `}

    ${({ variant }) =>
       variant === 'error' &&
       css`
          color: ${({ theme }) => theme.error};
       `}
`;

export const Icon = styled.div`
   svg {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.separator};
   }
`;

export const TopBarButtonWrapper = styled.div`
   padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()} 0;
   text-align: right;
   background-color: transparent;
   display: flex;
   align-items: center;

   > * + * {
      margin-left: ${({ theme }) => theme.spacing(0.5)};
   }

   ${media.desktop`
    padding: 0;
    position: static;
  `};

   ${({ variant, theme }) =>
      variant === 'spaced' &&
      `
    margin-left: ${theme.spacing(-1)};

    > span {
      margin-left: ${theme.spacing()};
    }
  `};

   ${({ variant, theme }) =>
      variant === 'nested' &&
      `
      padding: 0;
      margin-bottom: ${theme.spacing()};
    `};
`;

export const TopBarWrapper = styled.div`
   position: static;
   padding: 0;
   display: flex;
   flex-direction: column;
   background-color: transparent;

   ${media.desktop`
    position: fixed;
    top: 0;
    left: 280px;
    right: 0;
    z-index: 2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 ${({ theme }) => theme.separator};
    padding: ${({ theme }) => theme.spacing(1.25)}
    ${({ theme }) => theme.spacing(2)};
    height: 76px;
  `};

   ${props =>
      props.full &&
      css`
         right: 0;
      `};

   ${props =>
      props.followsLargeSidebar &&
      css`
         left: 320px;
      `};
`;

export const Tag = styled(Stack)`
   font-size: ${({ theme }) => theme.size(-1)};
   background-color: ${({ color }) =>
      tinycolor(color)
         .setAlpha(0.2)
         .toString()};
   color: ${({ color }) => color};
   border-radius: ${({ theme }) => theme.borderRadius};
   padding: ${({ theme }) => theme.spacing(0.125)} ${({ theme }) => theme.spacing(0.25)};
`;

export const SwipeableList = styled(InlineList)`
   flex-wrap: nowrap;
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
   margin-left: ${({ theme }) => theme.spacing(-1)};
   margin-right: ${({ theme }) => theme.spacing(-1)};
   width: 100vw;
   padding-left: ${({ theme }) => theme.spacing()};
   padding-right: ${({ theme }) => theme.spacing()};
   padding-bottom: ${({ theme }) => theme.spacing()};

   > * {
      flex: 0 0 calc(100vw - 32px);
      padding-right: ${({ theme }) => theme.spacing()};

      ~ * {
         margin-top: 0;
      }
   }

   ${media.tablet`
    display: block;
    width: auto;

    > * {
      padding-right: 0;

      ~ * {
        margin-top: ${({ theme }) => theme.spacing()};
      }
    }
  `};

   ${props =>
      props.variant === 'hasASingleItem' &&
      css`
         > * {
            flex: 0 0 100%;
            padding-right: 0;
         }
      `};

   + ${CardFooter} {
      padding-top: 0;
   }
`;

export const PlaceImage = styled.span`
   display: inline-block;
   width: ${({ theme }) => theme.spacing(4)};
   height: ${({ theme }) => theme.spacing(4)};
   background-image: url(${props => props.backgroundURL});
   background-size: cover;
   background-position: center center;
   border-radius: ${({ theme }) => theme.borderRadius};
   margin-right: ${({ theme }) => theme.spacing(0.25)};
`;

export const PlaceName = styled.p`
   margin: 0;
   font-weight: 600;
   line-height: ${spacing()};
`;

export const PlaceAgency = styled.p`
   margin: 0;
   color: ${({ theme }) => theme.textLight};
   line-height: ${spacing()};
   ${props =>
      props.white &&
      css`
         color: ${({ theme }) => theme.white};
      `};
`;

export const Badge = styled.div`
   position: absolute;
   top: -12%;
   left: 50%;
   font-size: 10px;
   line-height: 14px;
   background-color: white;
   border-radius: 8px;
   color: ${({ theme }) => theme.primary};
   height: 15px;
   width: max-content;
   min-width: 16px;
   text-align: center;
   vertical-align: middle;
   padding: 0 4px;

   ${props =>
      props.variant === 'primary' &&
      css`
         margin-left: ${spacing(0.5)};
         position: initial;
         background-color: ${({ theme }) => theme.primary};
         color: white;
      `};
`;

export const LoadingLayout = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
`;
